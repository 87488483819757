import { RawHtml } from '../../index'

import FeedingRecommendation from './FeedingRecommendation'
import Ingredients from './Ingredients'
import Ratings from './Ratings'

function productAdditionalInfo(productData, isMobile) {
  const {
    product_type: productType,
    longdesc: descriptionText,
    inhaltsstoffe_data_only: ingredientsData,
    futterungsempfehlung_data_only: recommendationData,
  } = productData

  const tabData = [
    {
      id: 'description',
      label: 'DESCRIPTION',
      component: RawHtml,
      props: { html: descriptionText, forceStrictMode: true },
    },
    {
      id: 'ingredients',
      label: 'INGREDIENTS',
      component: Ingredients,
      props: {
        ingredients: ingredientsData,
        productType,
        isMobile,
      },
    },
    {
      id: 'recommendation',
      label: 'FEEDING_RECOMMENDATION',
      component: FeedingRecommendation,
      props: {
        recommendation: recommendationData,
        productType,
      },
    },
    {
      id: 'ratings',
      label: 'RATINGS',
      component: Ratings,
      props: { productData },
    },
  ]

  // Filter out tabs without data to avoid showing empty tabs.
  // 'ingredients' and 'recommendation' can have different types: array, boolean, object
  const filteredTabData = tabData.filter((tab) => {
    if (tab.id === 'ingredients') {
      const isNonEmptyArray =
        Array.isArray(ingredientsData) && ingredientsData?.length > 0
      const hasNonArrayValue =
        !Array.isArray(ingredientsData) && !!ingredientsData
      if (isNonEmptyArray || hasNonArrayValue) {
        return tab
      }
    } else if (tab.id === 'recommendation') {
      const isNonEmptyArray =
        Array.isArray(recommendationData) && recommendationData.length > 0
      const hasNonArrayValue =
        !Array.isArray(recommendationData) && !!recommendationData
      if (isNonEmptyArray || hasNonArrayValue) {
        return tab
      }
    } else {
      return tab
    }
  })

  return filteredTabData
}

export default productAdditionalInfo
