import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

const Description = ({ content, label }) => {
  const [isOpened, setIsOpened] = useState(false)

  const onClick = () => setIsOpened(!isOpened)

  const headerClass = classNames('option-heading', {
    collapsed: isOpened,
  })
  const contentClass = classNames('option-content py-3', {
    'd-none': !isOpened,
  })

  return (
    <div className="option-card">
      <div className={headerClass} onClick={onClick}>
        <span>{label}</span>
      </div>
      <div className={contentClass}>{content}</div>
    </div>
  )
}

Description.propTypes = {
  content: PropTypes.any,
  label: PropTypes.string,
}

export default Description
