import React from 'react'
import { PropTypes } from 'prop-types'

import Bundle from './Bundle'
import Simple from './Simple'

const Ingredients = ({ ingredients, productType, isMobile }) => {
  if (!ingredients) {
    return null
  }

  let output = ''
  switch (productType) {
    case 'bundle':
      output = <Bundle ingredients={ingredients} isMobile={isMobile} />
      break
    case 'simple':
      output = <Simple ingredients={ingredients} isMobile={isMobile} />
      break
  }

  return output
}

Ingredients.propTypes = {
  ingredients: PropTypes.object,
  productType: PropTypes.string,
  isMobile: PropTypes.bool,
}

export default Ingredients
