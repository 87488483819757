import React from 'react'
import { PropTypes } from 'prop-types'

import { RawHtml } from '../../index'
import Description from './Description'

const FeedingRecommendation = ({ recommendation, productType }) => {
  if (!recommendation) {
    return null
  }

  let output = ''
  switch (productType) {
    case 'bundle':
      output = Object.keys(recommendation).map((key) => {
        const selections = recommendation[key]
        return (
          <div className="option-wrapper" key={key}>
            <span className="text-left text-dark font-weight-semi-bold d-block mb-2">
              {key}
            </span>
            {Object.keys(selections).map((selectionId, index) => {
              const { content, heading } = selections[selectionId]
              if (content?.length) {
                return (
                  <Description
                    key={index}
                    label={heading}
                    content={<RawHtml html={content} forceStrictMode={true} />}
                  />
                )
              }
            })}
          </div>
        )
      })
      break
    case 'simple':
      output = <RawHtml html={recommendation} />
      break
  }
  return output
}

FeedingRecommendation.propTypes = {
  recommendation: PropTypes.any,
  productType: PropTypes.string,
}

export default FeedingRecommendation
