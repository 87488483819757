import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'

import { RawHtml } from '../../..'
import { useTranslation } from '../../../../utils/providers'

import labels from './labels'

const RenderBundle = ({ selection }) => {
  const { t } = useTranslation()
  const output = selection?.map(({ key, value }) => {
    if (!value) {
      return null
    }
    const selectionLabel = labels[key] || key
    return (
      <Fragment key={key}>
        {t(selectionLabel)}
        <RawHtml html={value} element="div" forceStrictMode={true} />
        <br />
      </Fragment>
    )
  })

  return output || null
}

RenderBundle.propTypes = {
  selection: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}

export default RenderBundle
