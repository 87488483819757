import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'

import { RawHtml } from '../../../index'
import { useTranslation } from '../../../../utils/providers'

import labels from './labels'

const Simple = ({ ingredients, isMobile }) => {
  const { t } = useTranslation()
  const rowItemClasses = (flexColumnNum) =>
    classNames('col col-12', !isMobile && `col-md-${flexColumnNum}`)
  const output =
    ingredients.content?.map(({ key, value }, index) => {
      if (!value) {
        return null
      }

      const selectionLabel = labels[key] || key
      return (
        <div className="row mb-3 pb-3" key={index}>
          <div className={rowItemClasses(3)}>{t(selectionLabel)}</div>
          <div className={rowItemClasses(9)}>
            <RawHtml html={value} forceStrictMode={true} />
          </div>
        </div>
      )
    }) || null

  return output
}

Simple.propTypes = {
  ingredients: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  isMobile: PropTypes.bool,
}

export default Simple
