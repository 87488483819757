import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Link, RawHtml } from '../../index'
import {
  PricingContext,
  useSnippetContent,
  useTranslation,
} from '../../../utils/providers'
import { trackSubscriptionToggle } from '../../../utils/Alphapet/tracking'

const calculatePercentage = (regularPrice, discountPrice) => {
  const discountValue = discountPrice - regularPrice
  let percentage = (discountValue / regularPrice) * 100
  percentage = Math.round(percentage)
  percentage = percentage + '%'
  return percentage
}

function DeliveryBox({ productData }) {
  const { t } = useTranslation()
  const { pricingContext } = useContext(PricingContext) || {}
  const { link: aboInfoLink, detail: aboDetailText } =
    useSnippetContent('abo_box')?.aboDetails || {}
  const {
    priceinformation: [prices],
    availability: isAvailable,
  } = productData
  const { isAbo = false, getAboPrice } = pricingContext

  const onChangeDeliveryOption = (e) => {
    let aboChecked
    if (e.target.value === 'abo-delivery') {
      aboChecked = true
      pricingContext.setAbo(true)
    } else if (e.target.value === 'regular-delivery') {
      aboChecked = false
      pricingContext.setAbo(false)
    }
    trackSubscriptionToggle(
      productData.sku,
      getAboPrice(productData),
      aboChecked
    )
  }

  if (!prices || !isAvailable) {
    return null
  }

  const { regular: regularPrice, special: specialPrice } = prices
  const aboRegularPrice = regularPrice ? regularPrice : specialPrice
  const aboPrice = getAboPrice(productData)

  const aboDiscount = calculatePercentage(aboRegularPrice, aboPrice)
  let normalDiscountBox = t('PDP_ONE_TIME_DELIVERY_NO_DISCOUNT')
  if (regularPrice) {
    const normalDiscount = calculatePercentage(regularPrice, specialPrice)
    normalDiscountBox = t('PDP_ONE_TIME_DELIVERY_WITH_DISCOUNT')(normalDiscount)
  }

  return (
    <div className="pdp-delivery p-3">
      <input
        type="radio"
        id="regular-delivery"
        name="delivery-option"
        value="regular-delivery"
        checked={!isAbo}
        onChange={onChangeDeliveryOption}
      />
      <label htmlFor="regular-delivery" className="position-relative pl-4">
        {normalDiscountBox}
      </label>
      <div className="pdp-delivery__abo d-flex align-items-center">
        <input
          type="radio"
          id="abo-delivery"
          name="delivery-option"
          value="abo-delivery"
          checked={isAbo}
          onChange={onChangeDeliveryOption}
        />
        <label htmlFor="abo-delivery" className="position-relative pl-4 mb-0">
          {t('PDP_SUBSCRIPTION_WITH_DISCOUNT')(aboDiscount)}
        </label>
        <Link className="d-flex ml-2" href={aboInfoLink} title={t('MORE_INFO')}>
          <i className="cf-question-circle font-size-m" />
        </Link>
      </div>
      {isAbo && aboDetailText && <RawHtml html={aboDetailText} />}
    </div>
  )
}

DeliveryBox.propTypes = {
  productData: PropTypes.object,
}

export default DeliveryBox
