import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

import { debounce, loadReviewWidget } from '../../../utils'
import { trackProductRatingClick } from '../../../utils/Alphapet/tracking'

const Ratings = ({ productData }) => {
  const [pushedProducts, setPushedProducts] = useState([])

  const updateReviews = () => {
    const { id, parent_sku, produktgruppe_apv, sku } = productData
    loadReviewWidget(id, parent_sku, produktgruppe_apv, sku)
  }

  const handleScroll = debounce(() => {
    const { sku } = productData
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      const elements = document.getElementsByClassName('review-block')
      const defaultOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      }

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!pushedProducts.includes(entry.target.dataset.id)) {
              observer.unobserve(entry.target)
              setPushedProducts([...pushedProducts, entry.target.dataset.id])
              trackProductRatingClick(sku)
            }
          }
        })
      }

      const observer = new IntersectionObserver(callback, defaultOptions)
      Object.values(elements).forEach((element) => {
        observer.observe(element)
      })
    }
  }, 200)

  useEffect(() => {
    updateReviews()
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [productData.id])

  const name = `${productData.manufacturer_name} ${productData.name} ${productData.config_variable_a}`

  return (
    <div
      id="review-widget"
      data-product-id={productData.id}
      data-product-name={name}
      data-product-gtin=""
      data-product-ean=""
      data-url={productData.url[0]}
      data-imageurl={productData.images[0]}
      data-group-name={productData.parent_sku}
      data-product-attribute={productData.produktgruppe_apv}
      data-syndication-code={productData.sku}
    />
  )
}

Ratings.propTypes = {
  productData: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.arrayOf(PropTypes.string),
    images: PropTypes.arrayOf(PropTypes.string),
    manufacturer_name: PropTypes.string,
    name: PropTypes.string,
    config_variable_a: PropTypes.string,
    parent_sku: PropTypes.string,
    produktgruppe_apv: PropTypes.string,
    sku: PropTypes.string,
  }),
}

export default Ratings
