import React, { Fragment, useEffect, useState } from 'react'

import { Accordion, Tabs } from '../../index'
import {
  useFeatureFlags,
  useMediaResize,
  useTranslation,
} from '../../../utils/providers'
import { trackPDPInfoTabClick } from '../../../utils/Alphapet/tracking'
import productAdditionalInfo from '../productAdditionalInfo'

export default function AdditionalInfo({ productData }) {
  const { id: productId, sku } = productData
  const { isFeatureEnabled } = useFeatureFlags()
  const { breakpoints, initialDevice } = useMediaResize()
  const { t } = useTranslation()
  const [isMobile, setMobile] = useState(initialDevice === 'mobile')

  useEffect(() => {
    setMobile(breakpoints.downMD)
  }, [breakpoints.downMD])

  const onItemVisible = ({ id }) => {
    trackPDPInfoTabClick(id, sku)
  }

  const content = productAdditionalInfo(productData, isMobile)
    .map((item) => ({
      ...item,
      label: t(item.label),
    }))
    .filter(
      (item) => item.id !== 'ratings' || isFeatureEnabled('product.review')
    )

  // Make sure to provide a key to the fragment, so the tabs are re-rendered
  // for different products.
  return (
    <Fragment key={productId}>
      {isMobile ? (
        <Accordion
          className="mb-4 detailed-info"
          items={content}
          onItemVisible={onItemVisible}
        />
      ) : (
        <Tabs
          className="detailed-info"
          items={content}
          onItemVisible={onItemVisible}
        />
      )}
    </Fragment>
  )
}
