import React from 'react'
import { PropTypes } from 'prop-types'

import Description from '../Description'
import RenderBundle from './RenderBundle'

const Bundle = ({ ingredients }) => {
  const output = Object.keys(ingredients).map((key) => {
    const selections = ingredients[key]
    return (
      <div className="option-wrapper" key={key}>
        <span className="text-left text-dark font-weight-semi-bold d-block mb-2">
          {key}
        </span>
        {Object.entries(selections).map(([selectionId, selection]) => (
          <Description
            key={selectionId}
            label={selection.label}
            content={<RenderBundle selection={selection.content} />}
          />
        ))}
      </div>
    )
  })
  return output
}

Bundle.propTypes = {
  ingredients: PropTypes.objectOf(PropTypes.object),
}

export default Bundle
